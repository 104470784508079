<template>
    <div class="page page--lk">
        <LeftMenu v-if="userData" />

        <div class="info-container">
            <div class="mobile-header">
                <a href="/" class="logo">
                    <img src="/img/logo.svg" />
                </a>
                <label class="btn btn-round menu-btn">
                    <input
                        type="checkbox"
                        value="Y"
                        class="menu-toggle"
                        @click.prevent="toggleMobileMenu"
                    />
                    <span class="menu-btn__content" :class="{ 'active' : isMenuMobileActive }"></span>
                </label>
            </div>
            <div class="section-info">
                <div class="form-search section-info__form-search">
                    <button class="btn form-search__submit">
                        <svg class="icon icon-search ">
                            <use xlink:href="#search"></use>
                        </svg>
                    </button>
                    <input
                        type="text"
                        placeholder="Поиск по сайту"
                        class="input form-search__input"
                    />
                </div>

                <router-view v-slot="{ Component }">
                    <component :is="Component" />
                </router-view>
            </div>
        </div>

    </div>
</template>

<script>
import LeftMenu from "@/layouts/personal/components/left-menu";
import store from "@/store/index.js";
import { ref, provide, readonly } from "vue";

export default {
    components: { LeftMenu },

    beforeRouteEnter(to, from, next) {
        store.dispatch("PERSONAL_FETCH_USER_DATA").finally(() => {
            next();
        })
    },
    computed: {
        userData() {
            return this.$store.state.personal.user
        },
    },
    setup() {
        const isMenuMobileActive = ref(false);
        const toggleMobileMenu = () => {
            isMenuMobileActive.value = !isMenuMobileActive.value;
            const body = document.querySelector(".js-body");
            body.classList.toggle("body--fix");
        };

        provide("isMenuMobileActive", readonly(isMenuMobileActive));

        return { toggleMobileMenu, isMenuMobileActive };
    },
};
</script>
