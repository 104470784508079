<template>
    <div class="section-menu" :class="{ active: isActiveLeftMenu }">
        <div class="section-menu__header">
            <div class="logo">
                <img src="/img/logo.svg" />
            </div>

            <button
                class="btn btn-back"
                @click.prevent="toggleMenu"
                :class="
                    isActiveLeftMenu
                        ? 'section-menu__btn-active active'
                        : 'section-menu__btn'
                "
            >
                <svg class="icon icon-arrow-right-bold ">
                    <use xlink:href="#arrow-right-bold"></use>
                </svg>
                <template v-if="isActiveLeftMenu">Свернуть</template>
            </button>
        </div>

        <div class="section-menu__content">
            <div class="profile-info">
                <div class="profile-info__img" v-if="userData.avatar">
                    <img :src="userData.avatar" />
                </div>

                <div class="profile-info__img" v-else>
                    <img
                        src="/img/icons/avatar-student.svg"
                        v-if="userData.type === 'Обучаемый'"
                    />
                    <img src="/img/icons/avatar-customer.svg" v-else />
                </div>

                <div class="profile-info__text">
                    <div class="profile-info__name subtitle">
                        {{ userData.name }}
                    </div>
                    <div>{{ userData.type }}</div>
                </div>
            </div>

            <div class="nav">
                <router-link
                    class="nav__item"
                    v-for="(item, index) in menuLinks"
                    :key="index"
                    :to="item.path"
                    :class="{ 'active' : $route.path.includes(item.path) }"
                >
                    <svg class="icon icon-user" :class="`icon-${item.picture}`">
                        <use :xlink:href="`#${item.picture}`"></use>
                    </svg>
                    <span class="nav__item-name">{{ item.name }}</span>
                </router-link>

                <div class="nav__item nav__item--red" @click.prevent="logout">
                    <svg class="icon icon-SignOut">
                        <use xlink:href="#SignOut"></use>
                    </svg>
                    <div class="nav__item-name">Выход</div>
                </div>
            </div>
        </div>

        <Contacts />
    </div>

    <div
        class="menu-shadow"
        @click.prevent="toggleMenu"
        :class="{ active: isActiveLeftMenu }"
    ></div>
    <div class="menu-mobile">
        <div class="nav nav--fix">
            <router-link
                v-for="(item, index) in menuLinks"
                :key="index"
                :to="item.path"
                exact
                class="nav__item"
                :class="{ 'active' : $route.path.includes(item.path) }"

            >
                <svg class="icon icon-user" :class="`icon-${item.picture}`">
                    <use :xlink:href="`#${item.picture}`"></use>
                </svg>
                <span class="nav__item-name">{{ item.shortName }}</span>
            </router-link>
        </div>
        <div class="menu-body" :class="{ active: isMenuMobileActive.value }">
            <div class="nav nav--menu">
                <div class="nav__item nav__item--red" @click.prevent="logout">
                    <svg class="icon icon-SignOut ">
                        <use xlink:href="#SignOut"></use>
                    </svg>
                    <span class="nav__item-name">Выход</span>
                </div>
            </div>
            <Contacts />
        </div>
    </div>
</template>

<script>
import Contacts from "./contacts";

export default {
    components: {
        Contacts
    },
    inject: ['isMenuMobileActive'],

    data() {
        return {
            isActiveLeftMenu: false,
            menuLinksStudent: [
                {
                    name: "Личные данные",
                    shortName: "Профиль",
                    path: "/personal",
                    picture: "user"
                },
                {
                    name: "Доступные курсы",
                    shortName: "Курсы",
                    path: "/courses",
                    picture: "folder"
                },
                {
                    name: "База знаний",
                    shortName: "Архив",
                    path: "/knowledge",
                    picture: "Archive"
                },
                {
                    name: "Статистика",
                    shortName: "Статистика",
                    path: "/statistics",
                    picture: "chart"
                }
            ],
            menuLinksCustomer: [
                {
                    name: "Данные заказчика",
                    shortName: "Профиль",
                    path: "/personal",
                    picture: "user"
                },
                {
                    name: "Мои заказы",
                    shortName: "Заказы",
                    path: "/orders",
                    picture: "folder"
                },
                {
                    name: "Статистика и пароли",
                    shortName: "Статистика",
                    path: "/performance",
                    picture: "chart"
                },
                {
                    name: "Мои сотрудники",
                    shortName: "Cотрудники",
                    path: "/students",
                    picture: "users"
                }
            ],
            body: document.querySelector(".js-body")
        }
    },

    computed: {
        userData() {
            return this.$store.state.personal.user
        },
        menuLinks() {
            if (this.userData.role === "Заказчик") return this.menuLinksCustomer
            else return this.menuLinksStudent
        }
    },

    methods: {
        toggleMenu() {
            this.isActiveLeftMenu = !this.isActiveLeftMenu
            this.body.classList.toggle("menu-active")
            if (window.innerWidth < 768) this.body.classList.toggle("body--fix")
        },
        logout() {
            this.$store
                .dispatch("AUTH_LOGOUT")
                .then(() => this.$router.push("/login"))
            this.isActiveLeftMenu = false;
            this.body.classList.remove("menu-active")
            if (window.innerWidth < 768) this.body.classList.remove("body--fix");
        }
    },

    mounted() {
       if (window.innerWidth > 768) this.toggleMenu()
    }
};
</script>
